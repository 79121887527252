// cache.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RfqCacheService {
  private impa: any;
  private rfqFormId: any;
  private headerDataImpa: any;
  private lineItemDataImpa: any;
  private paymentTermsDataImpa: any;
  private lineItemTableDataImpa:any;

  set rfqId(rfqFormId: any) {
    this.rfqFormId = rfqFormId;
  }

  get rfqId() {
    return this.rfqFormId;
  }

  set impaToRaiseRfq(impaItem: any) {
    this.impa = impaItem
  }

  set headerData(data: any) {
    this.headerDataImpa = data;
  }

  set lineItemData(data: any) {
    this.lineItemDataImpa = data;
  }

  set paymentTermsData(data: any) {
    this.paymentTermsDataImpa = data;
  }

  set lineItemTableData(data: any) {
    this.lineItemTableDataImpa = data;
  }


  get paymentTermsData() {
    return this.paymentTermsDataImpa;
  }

  get headerData() {
    return this.headerDataImpa;
  }

  get lineItemData() {
    return this.lineItemDataImpa;
  }

  get impaToRaiseRfq() {
    return this.impa;
  }

  get lineItemTableData(){
    return this.lineItemTableDataImpa
  }
}
