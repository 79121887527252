import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Page } from "../models/page";

@Injectable({
  providedIn: "root",
})
export class WebApiService {
  private baseUrl: string = environment.apiURL;
  constructor(private httpClient: HttpClient) { }

  /**
   * HTTP : GET REQUEST FOR PAGINATED DATA
   * @param apiUrl Get api url with paging params.
   * @param _reqParams Request params for paginated result.
   * Returns observable<T> response.
   */
  getPaginatedRequest<T>(
    apiUrl: string,
    _reqParams: HttpParams
  ): Observable<T> {
    return this.httpClient
      .get(this.baseUrl + apiUrl, { params: _reqParams })
      .pipe<T>(
        map((mappedRes: any) => {
          // Set page object to default on each pagination iteration.
          mappedRes.data.page = new Page();
          return mappedRes.data;
        })
      );
  }

  postPaginatedRequest<T>(
    apiUrl: string,
    reqBody: T | any,
    _reqParams: HttpParams
  ): Observable<T> {
    return this.httpClient.post(this.baseUrl + apiUrl, reqBody, { params: _reqParams }).pipe<T>(
      map((mappedRes: any) => {
        // Set page object to default on each pagination iteration.
        mappedRes.data.page = new Page();
        return mappedRes.data;
      })
    );
  }

  /**
   * HTTP GET REQUEST [WIHOUT PARAMS]
   * @param apiUrl Get api url.
   */
  getRequest<T>(apiUrl: string, _params?: HttpParams): Promise<T> {
    return this.httpClient
      .get(this.baseUrl + apiUrl, {params: _params})
      .pipe(
        map((res: any) => {
          return res.data?.rows || res.data;
        })
      )
      .toPromise();
  }

  getRequest1<T>(apiUrl: string, _params?: HttpParams): Promise<T> {
    return this.httpClient
      .get(this.baseUrl + apiUrl, {params: _params})
      .pipe(
        map((res: any) => {
          return res.data;
        })
      )
      .toPromise();
  }

  /**
   * HTTP POST REQUEST
   * @param apiUrl Post api url.
   * @param reqBody Post request data.
   */
  postRequest<T>(apiUrl: string, reqBody: T | any, _httpOptions: T | any = {}): Promise<T> {
    return this.httpClient
      .post<T>(this.baseUrl + apiUrl, reqBody, _httpOptions)
      .pipe(
        map((res: any) => {
          return res.data;
        })
      )
      .toPromise();
  }


  /**
   * HTTP PUT REQUEST
   * @param apiUrl Put api url.
   * @param reqBody Put request data.
   */
  putRequest<T>(apiUrl: string, reqBody: T | any): Promise<T> {
    return this.httpClient
      .put<T>(this.baseUrl + apiUrl, reqBody)
      .pipe(
        map((res: any) => {
          return res.data;
        })
      )
      .toPromise();
  }

  /**
   * HTTP DELETE REQUEST
   * @param apiUrl Delete api url.
   */
  deleteRequest<T>(apiUrl: string, _params?: HttpParams): Promise<T> {
    return this.httpClient
      .delete<T>(this.baseUrl + apiUrl, {params: _params})
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  /**
   * HTTP GET REQUEST AS OBSERVABLE [WIHOUT PARAMS]
   * @param apiUrl Get api url.
   * Returns observable<T> response.
   */
  getRequestObservable<T>(apiUrl: string): Observable<T> {
    return this.httpClient.get(this.baseUrl + apiUrl).pipe<T>(
      map((res: any) => {
        return res.data?.rows || res.data;
      })
    );
  }

  /**
   * HTTP GET REQUEST AS OBSERVABLE [WIHOUT PARAMS]
   * @param apiUrl Get api url.
   * Returns observable<T> response.
   */
   getRequestObservableParams<T>(apiUrl: string, _reqParams: HttpParams): Observable<T> {
    return this.httpClient.get(this.baseUrl + apiUrl, { params: _reqParams }).pipe<T>(
      map((res: any) => {
        return res.data?.rows || res.data;
      })
    );
  }

  /**
   * HTTP POST REQUEST AS OBSERVABLE
   * @param apiUrl Post api url.
   * @param reqBody Post request data.
   * Returns observable<T> response.
   */
  postRequestObservable<T>(apiUrl: string, reqBody: T | any): Observable<T> {
    return this.httpClient.post(this.baseUrl + apiUrl, reqBody).pipe<T>(
      map((res: any) => {
        return res.data;
      })
    );
  }

  /**
   * HTTP PUT REQUEST AS OBSERVABLE
   * @param apiUrl Put api url.
   * @param reqBody Put request data.
   * Returns observable<T> response.
   */
  putRequestObservable<T>(apiUrl: string, reqBody: T | any): Observable<T> {
    return this.httpClient.put(this.baseUrl + apiUrl, reqBody).pipe<T>(
      map((res: any) => {
        return res.data;
      })
    );
  }

  /**
   * HTTP DELETE REQUEST AS OBSERVABLE
   * @param apiUrl Delete api url.
   * Returns observable<T> response.
   */
  deleteRequestObservable<T>(apiUrl: string): Observable<T> {
    return this.httpClient
      .delete(this.baseUrl + apiUrl)
      .pipe<T>(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * HTTP GET REQUEST [ for calling external apis]
   * @param apiUrl Get api url.
   */
  getS3FileAsBlob<T>(apiUrl: string, _params?: HttpParams): Promise<T> {
    return this.httpClient
      .get(apiUrl, {params: _params,responseType:'blob' as 'blob'})
      .pipe(
        map((res: any) => {
          console.log(res,"res")
          return res;
        })
      )
      .toPromise();
  }

  /**
   * HTTP PUT REQUEST FOR EXTERNAL API CALL 
   * @param apiUrl Put api url.
   * @param reqBody Put request data.
   */
  putRequestExternalApi<T>(apiUrl: string, reqBody: T | any): Promise<T> {
    // console.log('got options for put request==>',apiUrl, reqBody)
    return this.httpClient
      .put<T>(apiUrl, reqBody)
      .pipe(
        map((res: any) => {
          // console.log('got file upload response==>',res)
          return res?.data? res.data:res;
        })
      )
      .toPromise<T>();
  }

    
  getCardDataRequest<T>(apiUrl: string, _params?: HttpParams): Promise<T> {

    return this.httpClient

      .get(this.baseUrl + apiUrl, {params: _params})

      .pipe(

        map((res: any) => {

          return res.data;

        })

      )

      .toPromise();

  }

}
