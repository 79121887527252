<!-- <app-embedded-loader [show]="showLoader"></app-embedded-loader> -->

<div class="card" class="supplier_preview">
  <div class="card-header">
    <div class="sidebar-title">
      <div class="sidebar-title-text">{{title}}</div>
      <div class="col-lg-2 col-md-2 col-2 text-right">
        <span style="float: right;" class="pointer header-close-btn" matTooltipClass="mat-tooltip-custom" matTooltip="Close"
          matTooltipPosition="below" (click)="closeForm()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
  </div>

  <div class="pos-relative">
    <app-embedded-loader [show]="showLoader"></app-embedded-loader>
    <div class="card-body fix-hed" [ngClass]="showLoader ? 'oy-hidden' : ''">
      <form [formGroup]="supplierFormData" (ngSubmit)="SubmitForm()" autocomplete="off">
        <fieldset>
          <legend></legend>
          <div class="form-row">
            <div class="form-group col-lg-12">
              <div formArrayName="emailAddressArray"
                *ngFor="let item of supplierFormData.get('emailAddressArray')['controls']; let i = index" class="d-flex">
                <div class="pl-0" [ngClass]="isAdmin ? 'col-lg-10' : 'col-lg-10'" [formGroupName]="i">
                  <mat-form-field class="full-width" color="accent">
                    <input matInput type="email" name="email{{item}}" formControlName="emailAddress"
                      placeholder="Email Address {{i+1}}" maxlength="255" (blur)="addEmailData(i)"
                      pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                      [required]="i===0 ? true : false" />
                  </mat-form-field>
                  <small
                    *ngIf="isFormSubmitted || supplierFormData.controls['emailAddressArray']['controls'][i].controls.emailAddress.dirty || supplierFormData.controls['emailAddressArray']['controls'][i].controls.emailAddress.touched"
                    class="form-error-msg">
  
                    <span *ngIf=" supplierFormData.controls['emailAddressArray']['controls'][i].controls.emailAddress.hasError('required')
                    ">Email Address {{i+1}} should not be empty.
                    </span>
                    <span *ngIf="supplierFormData.controls['emailAddressArray']['controls'][i].controls.emailAddress.hasError('pattern')
                    ">Invaild Email Address {{i +1}}
                    </span>
                    <span *ngIf="emailValidationMessage[i]" class="form-success-msg">{{ emailValidationMessage[i] }}
                    </span>
                  </small>

                  <!-- <small *ngIf="emailValidationMessage[i]" class="form-error-msg">
                    {{ emailValidationMessage[i] }}
                  </small> -->

                </div>
                <div class="col-lg-1 p-0">
                  <span *ngIf="enableAddButton && i===0" color="primary" mat-icon-button popoverTitle="Add Email" matTooltipPosition="above" (click)="emailData(0,'Add')" class="add-btn">
                    <mat-icon matTooltipClass="mat-tooltip-custom" matTooltip="Add Email"
                      matTooltipPosition="below">add</mat-icon>
                  </span>
                  <span
                    *ngIf="i>0 && supplierFormData.controls['emailAddressArray']['controls'][i].controls.isActive.value"
                    color="warn" mat-icon-button popoverTitle="Delete Email" matTooltipPosition="above"
                    (click)="emailData(i,'Delete')" matTooltipClass="mat-tooltip-custom" matTooltip="Delete Email"
                    matTooltipPosition="below" class="d-icon pointer">
                    <mat-icon>delete</mat-icon>
                  </span>
                  <span
                    *ngIf="i>0 && !supplierFormData.controls['emailAddressArray']['controls'][i].controls.isActive.value"
                    color="primary" mat-icon-button popoverTitle="Restore Email" matTooltipPosition="above"
                    (click)="emailData(i,'Restore')" matTooltipClass="mat-tooltip-custom" matTooltip="Restore Email"
                    matTooltipPosition="below">
                    <mat-icon>restore</mat-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row" *ngIf="
          !isUpdateRequest
        " >
            <div class="form-group col-lg-12"
              style="display: flex; flex-direction: row; align-items: center; margin-bottom: 32px; margin-top: 0px; ">
              <button mat-flat-button color="primary" style="background-color: #74c5b5; margin-right: 16px;"
                (click)="checkIfSupplierExists()">Validate Supplier Emails</button>
              <small *ngIf="
                isValidateTrue
              " class="form-success-msg">
                <div>Supplier Email is available</div>
              </small>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="companyName" formControlName="companyName" placeholder="Organization Name"
                  minlength="1" maxlength="255" required whiteSpaceValidate />
              </mat-form-field>
              <small *ngIf="
                isFormSubmitted ||
                supplierFormData.controls['companyName'].dirty ||
                supplierFormData.controls['companyName'].touched
              " class="form-error-msg">
                <div *ngIf="
                  supplierFormData.controls['companyName'].hasError('required')
                ">Supplier Name should not be empty.
                </div>
              </small>
            </div>
          </div>

          <!-- Vendor Code -->
          <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="vendorCode" formControlName="vendorCode" placeholder="Vendor Code"
                  maxlength="255" />
              </mat-form-field>
            </div>
          </div>
  
          <!-- VSSupplier Code -->
          <!-- <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="vsSupplierCode" formControlName="vsSupplierCode" placeholder="VS Supplier Code"
                  maxlength="255" />
              </mat-form-field>
            </div>
          </div> -->
  
          <!-- First Name & Last Name -->
          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="firstName" formControlName="firstName" placeholder="First Name" maxlength="255" />
              </mat-form-field>
            </div>
  
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="lastName" formControlName="lastName" placeholder="Last Name" maxlength="255" />
              </mat-form-field>
            </div>
          </div>
  
          <!-- Contact Person & Business register number -->
          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="contactPerson" formControlName="contactPerson" placeholder="Contact Person"
                  maxlength="255" />
              </mat-form-field>
            </div>
  
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="businessRegisterNumber" formControlName="businessRegisterNumber"
                  placeholder="Business Register Number" maxlength="25" />
              </mat-form-field>
            </div>
          </div>
  
          <!-- <div class="form-row">
            <div class="form-group col-lg-12">
              <mat-form-field class="full-width" color="accent">
                <textarea matInput name="address" formControlName="address" placeholder="Address"
                  maxlength="255"></textarea>
              </mat-form-field>
            </div>
          </div> -->
          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-form-field appearance="fill" class="full-width" color="accent">
                  <mat-label>Street</mat-label>
                  <input matInput placeholder="Street" formControlName="street">
              </mat-form-field>
            </div>
              <div class="form-group col-lg-6">
                  <app-paginated-autocomplete [displayWithFunc]="filterFieldCountryNameDisplayFn"
                      [dataSrc]="boundGetCountryAutoComplete" [controlName]="'country'" [control]="getTradingAddressCountryControl()"
                      [placeholder]="'Country'" [label]="'Country'" [readonly]="false"  (autoCompleteOptionSelected)="onCountrySelected($event)">
                  </app-paginated-autocomplete>
              </div>
            </div>
          

          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <mat-label>Region*</mat-label>
                <mat-select formControlName="region" (selectionChange)="onRegionSelected($event.value)" >
                    <mat-option *ngFor="let regions of countryRegions" [value]="regions.iso">{{ regions.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              </div>
              <div class="form-group col-lg-6">
                <mat-form-field class="full-width" color="accent">
                  <mat-label>City*</mat-label>
                  <mat-select formControlName="city">
                      <mat-option *ngFor="let cities of regionCities" [value]="cities.name">{{cities.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              </div>
            </div>
          

          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-form-field appearance="fill" class="full-width" color="accent">
                <mat-label>Postcode / Zip</mat-label>
                <input matInput placeholder="Postcode / Zip" formControlName="zipCode">
              </mat-form-field>
              </div>
              <div class="form-group col-lg-6">
                <mat-form-field class="full-width" color="accent" class="full-width">
                  <input matInput name="website" type="url" formControlName="website" placeholder="Website"
                    maxlength="255"
                    pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" />
                </mat-form-field>
              </div>
            </div>

          <!-- Website -->
          <!-- <div class="form-row">
            <div class="form-group col-lg-12">
              
              
              
              <small *ngIf="isFormSubmitted ||
                supplierFormData.controls['website'].dirty ||
                supplierFormData.controls['website'].touched
              " class="form-error-msg">
                <div *ngIf="
                    supplierFormData.controls['website'].hasError('pattern')
                  ">
                  Invaild web site.
                </div>
              </small>
            </div>
          </div> -->
  
          <!-- Mobile Number 1 & Mobile Number 2 -->
          <div class="form-row">
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="mobileNumber1" formControlName="mobileNumber1" placeholder="Mobile Number 1"
                  maxlength="25"
                  onkeypress="return event.charCode >= 32 && event.charCode <= 64 ||  event.charCode >= 91 && event.charCode <= 96 ||  event.charCode >= 123 && event.charCode <= 126" />
              </mat-form-field>
            </div>
  
            <div class="form-group col-lg-6">
              <mat-form-field class="full-width" color="accent">
                <input matInput name="mobileNumber2" formControlName="mobileNumber2" placeholder="Mobile Number 2"
                  maxlength="25"
                  onkeypress="return event.charCode >= 32 && event.charCode <= 64 ||  event.charCode >= 91 && event.charCode <= 96 ||  event.charCode >= 123 && event.charCode <= 126" />
              </mat-form-field>
            </div>
          </div>

  
          <!-- Email Address-->



          <!-- Action Buttons -->
          <!-- <div class="form-row card-action-center">
            <div class="form-group col-md-6 text-right">
              <button-loading [color]="'primary'" [loading]="isButtonloading" [toolTip]="title">
                <mat-icon>save</mat-icon>
              </button-loading>
            </div>
            <div class="form-group col-md-6 text-left">
              <button matTooltipClass="mat-tooltip-custom" matTooltip="Close" matTooltipPosition="above" mat-mini-fab color="warn" type="button"
                (click)="closeForm()">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div> -->

          <div class="button-container">
            <button-loading class="save-button" [loading]="isButtonloading" [toolTip]="title">
              <mat-icon>save</mat-icon>
            </button-loading>
            <button matTooltipClass="mat-tooltip-custom" matTooltip="Close" matTooltipPosition="above"
              class="close-button" type="button" (click)="closeForm()">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>

        </fieldset>
      </form>
    </div>
  </div>
</div>